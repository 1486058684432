<template>

<div class="head">

	<div class="head-main">

		<router-link :to="$link('', $store.getters['context'].url, $store.getters['language'].code)" class="head-logo"></router-link>


		<div class="head-phone">

			<app-icon s="phone" :inline="true" /> Helpline <b>{{ $store.getters['context'].phone }}</b> 

		</div>

		<div class="head-donate" v-if="head.donate">

			<router-link :to="$link(head.donate)" class="head-donate-link">Donate</router-link>

		</div>

		<div class="head-toggle is-search" v-on:click="$emit('search')">

			<app-icon s="search" /> <span>Search</span>

		</div>

		<div class="head-toggle" v-on:click="toggle = !toggle">

			<app-icon s="menu" /> <span>Menu</span>

		</div>

	</div>

	<div class="head-menu" v-if="toggle">
		
		<div class="head-menu-list">
			
			<template v-for="(page, index) in secondaryTop">

				<router-link v-if="page.url !== '#'" :to="$link(page.url)" class="head-menu-item" :key="index"><span>{{ page.name }}</span> <com-submenu :pages="submenuPages('secondary', page.id)" :is-expanded="expand === page.id" v-if="page.parent" class="head-menu-item-submenu" /></router-link>
				<div v-if="page.url === '#'" v-on:click="expand = page.id" class="head-menu-item" :key="index"><span>{{ page.name }}</span> <com-submenu :pages="submenuPages('secondary', page.id)" :is-expanded="expand === page.id" v-if="page.parent" class="head-menu-item-submenu" /></div>

			</template>

		</div>
		
		<div class="head-menu-list">

			<template v-for="(page, index) in primaryTop">

				<router-link v-if="page.url !== '#'" :to="$link(page.url)" class="head-menu-item" :key="index"><span>{{ page.name }}</span> <com-submenu :pages="submenuPages('primary', page.id)" :is-expanded="expand === page.id" v-if="page.parent" class="head-menu-item-submenu" /></router-link>
				<div v-if="page.url === '#'" v-on:click="expand = page.id" class="head-menu-item" :key="index"><span>{{ page.name }}</span> <com-submenu :pages="submenuPages('primary', page.id)" :is-expanded="expand === page.id" v-if="page.parent" class="head-menu-item-submenu" /></div>

			</template>

		</div>

		<div class="head-language" v-on:click="selecting = !selecting">

			<div class="head-language-prefix">Select region</div>
			<div class="head-language-name">{{ $store.getters['language'].name }}</div>
			<div class="head-language-flag" v-bgimage="$store.getters['language'].flag" />
			<app-icon s="chevronDown" class="head-language-chevron" v-if="!selecting" />
			<app-icon s="chevronUp" class="head-language-chevron" v-if="selecting" />

		</div>

		<div class="head-languages" v-if="selecting">

			<router-link :to="{path: languagePath(language.code)}" class="head-languages-item" :class="{'is-active': language.code === $store.getters['language'].code}" v-bgimage="language.flag" v-for="(language, index) in head.languages" :key="index"></router-link>

		</div>

	</div>
	

</div>

</template>

<script>

export default {

	props: ['head'],

	components: {
		'com-submenu': () => import('./Submenu')
	},

	data: function() {

		return {
			expand: false,
			toggle: false,
			selecting: false
		}

	},

	watch: {

		path: function() {

			this.toggle = false
			this.expand = false

		}

	},

	computed: {

		primaryTop: function() {

			return this.$_.filter(this.head.primary, function(page) {

				return page.parent === true || page.parent === false

			})

		},

		secondaryTop: function() {

			return this.$_.filter(this.head.secondary, function(page) {

				return page.parent === true || page.parent === false

			})

		}

	},

	methods: {

		languagePath: function(code) {

			var path = this.$route.path

			path = path.replace(this.$store.getters['language'].code.toLowerCase() + '/', '')
			path = (path === '/' + this.$store.getters['language'].code.toLowerCase()) ? '' : path
			path = path.replace(this.$store.getters['context'].url.toLowerCase() + '/', '')
			path = (path === '/' + this.$store.getters['context'].url.toLowerCase()) ? '' : path

			var appendContext = false
			
			if (this.$store.getters['context'].url !== 'aa' && '/' + this.$store.getters['context'].url !== path) appendContext = true

			if (code !== 'UK') path = '/' + code.toLowerCase() + path

			if (appendContext) path = '/' + this.$store.getters['context'].url + path

			if (!path) path = '/'

			return path

		},

		submenuPages: function(type, id) {

			return this.$_.filter(this.head[type], function(page) {

				return page.parent === id

			})
		
		}

	}

}

</script>

<style scoped>

.head {
	height: 80px;
	position: fixed;
	z-index: 100;
	top: 0px;
	width: 100%;
	border-bottom: 1px solid #f5f5f5;
	transition: top 100ms linear;
}

.is-mobile .head {
	height: 96px;
}

.head-menu {
	position: fixed;
	height: calc(100vh - 80px);
	background-color: #1A234C;
	width: 100%;
	overflow-y: auto;
	z-index: 100;
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
}

.is-mobile .head-menu {
	height: calc(100vh - 96px);
	padding: 10px;
	margin-top: 28px;
}

.head-menu-list {
	width: calc(50% - 10px);
	display: flex;
	flex-direction: column;
}

.is-mobile .head-menu-list {
	width: calc(50% - 5px);
}

.head-menu-item {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.head-main {
	align-items: center;
	display: flex;
	background-color: #fff;
}

.is-mobile .head-main {
	height: 68px;
}

.head-logo {
	display: block;
	z-index: 2;
	width: 167px;
	flex-grow: 1;
	height: 80px;
	background-color: #fff;
	background-position: 0% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.is-mobile.head-logo {
	height: 68px;
}

.is-aa .head-logo {
	background-image: url(~@/assets/logo.aa.png);
}

.is-afa .head-logo {
	background-image: url(~@/assets/logo.afa.png);
}

.is-stars .head-logo {
	background-image: url(~@/assets/logo.stars.png);
}

.head-phone {
	color: #1A234C;
	font-size: 13px;
	letter-spacing: 1.3px;
	line-height: 32px;
	text-transform: uppercase;
	padding: 0px 10px;
	margin-left: 20px;
}

.head-phone b {
	letter-spacing: 0px;
}

.is-mobile .head-phone {
	position: absolute;
	left: 0px;
	bottom: -28px;
	height: 28px;
	width: 100%;
	background-color: #1A234C;
	color: #fff;
	line-height: 28px;
	padding: 0px;
	margin: 0px;
	text-align: center;
	z-index: 2;
}

.head-donate {
	margin-left: 20px;
}

.head-donate-link {
	height: 36px;
	display: block;
	background-color: #C21920;
	text-transform: uppercase;
	color: #fff;
	padding: 0px 20px;
	line-height: 36px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 19px;
}

.is-mobile .head-donate-link {
	font-size: 12px;
	padding: 0px 10px;
}

.head-toggle {
	width: 64px;
	height: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.head-toggle.is-search {
	margin-left: 16px;
}

.head-toggle:last-child {
	margin-right: 16px;
}

.head-toggle >>> .icon {
	font-size: 24px;
}

.head-toggle span {
	margin-top: 5px;
	color: #1A234C;
	font-size: 10px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.head-language {
	height: 48px;
	width: 100%;
	z-index: 2;
	border: 1px solid #fff;
	border-radius: 23px;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
	padding: 0px 20px;
	align-items: center;
	margin-top: 40px;
}

.head-language-prefix {
	flex-grow: 1;
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #fff;
	text-transform: uppercase;
}

.head-language-name {
	color: #fff;
	font-size: 16px;
}

.head-language-flag {
	width: 26px;
	height: 26px;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 50%;
	margin-left: 10px;
}

.head-language-chevron {
	color: #fff;
	font-size: 16px;
	margin-left: 10px;
}

.head-languages {
	display: flex;
	flex-wrap: wrap;
	padding: 15px;
}

.head-languages-item {
	height: 34px;
	width: 34px;
	display: flex;
	align-items: center;
	margin: 5px;
	cursor: pointer;
	border: 4px solid #1A234C;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 50%;
}

.head-languages-item.is-active {
	border: 4px solid #1277D4;
	border-radius: 50%;
}

</style>
